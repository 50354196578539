@import '../../../style/varibale';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,700&display=swap');

.app__summary {
    padding-top: 70px;
    padding-bottom: 100px;

    .xs_col {
        @media screen and (width < 576px) {
            width: 50%;
        }
    }

    .box_summary {
        background-color: #EBF0F3;
        border: 1px solid #BFC2C3;
        padding: 26px 30px;
        @media screen and (width < 1025px) {
            padding: 26px 20px;
        }
        .title_summary {
            span {
                color: $mainColor;
                font-size: 25px;
                font-weight: 700;

                @media screen and (width < 767px) {
                    font-size: 20px;
                }
                @media screen and (width < 1025px) {
                    font-size: 20px ;
                }
            }
        }

        .img_summary {
            margin-top: 20px;

            img {
                @include size(70px, 70px);

                @media screen and (width < 767px) {
                    @include size(60px, 60px);

                }
            }
        }

        .footer__summary {
            margin-top: 15px;

            .num {
                color: $dark;
                font-size: 40px;
                font-weight: 800;

                @media screen and (width < 767px) {
                    font-size: 30px;
                }
            }

            .text {
                color: $dark;
                font-size: 18px;
                font-weight: 800;

                @media screen and (width < 767px) {
                    font-size: 16px;
                }
            }
        }
    }
}


.app__summary_title {
    .header {
        h3 {
            font-size: 50px;
            color: $mainColor;
            font-weight: 700;
            font-family: 'Roboto', sans-serif ;
            position: relative;
            width: fit-content;
            z-index: 1;

            @media screen and (width < 767px) {
                font-size: 30px;
            }

            &::after {
                content: '';
                position: absolute;
                left: -10px;
                bottom: 10%;
                background-color: #E7E6E7;
                border-radius: 11px;
                width: 54%;
                height: 25px;
                z-index: -1;

                @media screen and (width < 767px) {
                    height: 20px;
                    bottom: 0%;
                }
            }
        }
    }

    p {
        font-size: 30px;

    }
}