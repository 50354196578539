@import './varibale';
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap');

.m_navbar_body {
    margin-top: calc( 88px + 50px);
}
[dir="rtl"] *{
    font-family:  $fontBase !important;
}

.loading__page{ 
    .loading_content{
        height: 100vh;
        background-color:rgba($color: #fff, $alpha: 0.8) !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}