@import '../../../style/varibale';

.app__services {
    padding: 120px 0 50px 0;
    background: linear-gradient(0deg, #5594FF 0%, #1D51AA 80%);

    @media screen and (width < 800px) {
        padding: 50px 0 50px 0;
    }

    .app__services_content {
        position: relative;

        img {

            @media screen and (width < 680px) {
                height: 250px;
                object-fit: contain;
            }
        }

        .services1,
        .services2,
        .services3 {
            position: absolute;
            width: 236px;

            @media screen and (width < 680px) {
                width: 160px;
            }

            h1 {
                font-size: 30px;
                font-weight: 700;
                color: $light;
                font-family: $fontRoboto;

                @media screen and (width < 680px) {
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
                color: $light;
                line-height: 21px;

                @media screen and (width < 680px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

    }

}

[dir="ltr"] .app__services .app__services_content .services1 {
    top: 40%;
    left: 12%;

    @media screen and (width < 680px) {
        top: -32px;
        left: 4%;
    }
}

[dir="ltr"] .app__services .app__services_content .services2 {
    top: 40%;
    left: 45%;

    @media screen and (width < 680px) {
        top: 60%;
        left: 30%;
    }
}

[dir="ltr"] .app__services .app__services_content .services3 {
    top: 30%;
    right: 8%;
}

[dir="rtl"] .app__services .app__services_content .services1 {
    top: 40%;
    right: 15%;
}

[dir="rtl"] .app__services .app__services_content .services2 {
    top: 18%;
    left: 42%;
}

[dir="rtl"] .app__services .app__services_content .services3 {
    top: 28%;
    left: 10%;
}

.app__services_title {
    .header {
        h3 {
            font-size: 40px;
            color: $light;
            font-weight: 600;
            font-family: 'Roboto', sans-serif;
            position: relative;
            width: fit-content;
            z-index: 1;

            @media screen and (width < 767px) {
                font-size: 30px;
            }

            &::after {
                content: '';
                position: absolute;
                right: -10px;
                bottom: 10%;
                background-color: rgba($color: $dark, $alpha: 20%);
                border-radius: 11px;
                width: 54%;
                height: 20px;
                z-index: -1;

                @media screen and (width < 767px) {
                    bottom: 0%;
                }
            }
        }
    }

    p {
        font-size: 24px;
        line-height: 32px;
        font-family: $fontRoboto;
        margin-top: 28px;
        color: $light;
        font-weight: 300;

        @media screen and (width < 680px) {
            font-size: 16px;
            line-height: 27px;
            margin-top: 20px;

        }
    }
}

.view_Lg {
    display: block;

    @media screen and (width < 956px) {
        display: none;
    }
}

.view_sm {
    display: none;

    .vertical-timeline-element-icon {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }

    @media screen and (width < 956px) {
        display: block;
    }
}