@import '../../style/varibale';

.app__ourClicnts {
    background-color: #F5F7FC;
}

.map-contactus {
    position: relative;

    .location1,
    .location2,
    .location3 {
        position: absolute;
        width: 14px !important;
        height: 14px !important;
        border-radius: 50% !important;
        background-color: #000 !important;
        cursor: pointer;
    }
    .loactionActive{
        background-color: red !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    }
    .location1 {
        top: 40% !important;
        left: 33.8% !important;
    }

    .location2 {
        bottom: 33% !important;
        right: 22.7% !important;
    }

    .location3 {
        bottom: 9% !important;
        left: 54% !important;
    }

}

.addres {
    text-align: center;
    border-radius: 10px;
    padding: 15px;

    h3 {
        margin: 0 !important;
        padding: 0 !important;

        @media screen and (width < 767px) {
            font-size: 16px;
        }

        @media screen and (width <400px) {
            font-size: 14px;
            margin-left: 33px !important;
        }
    }

    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 30px 60px -12px inset,
    rgba(50, 50, 93, 0.00) 0px 18px 36px -18px inset;
    position: relative;

    .icons {
        position: absolute;
        top: 0;
        border-radius: 10px;
        height: calc(33px + 33px);
        width: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $mainColor;

        @media screen and (width < 767px) {
            font-size: 16px;
        }

        @media screen and (width <400px) {
            height: 100%;
            width: 45px;
        }
    }

}

[dir="ltr"] .addres .icons {
    left: 0;
}

[dir="rtl"] .addres .icons {
    right: 0;
}

[dir="ltr"] .p-confirm-popup-location1 {

    .col {
        padding: 0rem !important;

        img {
            width: 150px !important;
        }

        h4 {
            color: $mainColor;
        }
    }
}

[dir="rtl"] .p-confirm-popup-location1 {

    margin-top: 10px;
}

.p-confirm-popup-content {
    padding: 0 !important;
}

.p-confirm-popup:before {
    display: none !important;
}

.p-confirm-popup {
    width: 350px;

    @media screen and (width < 400px) {
        width: 100%;
        left: 0 !important;
    }

    .p-confirm-popup-message {
        width: 100%;
    }
}

.p-confirm-popup {
    margin-top: 10px;
}