@import '../../../style/varibale';

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: $mainColor !important;
}

.p-galleria .p-galleria-indicators {
    background-color: transparent !important;
}

.image__container {
    position: relative;
    width: 100%;
    // height: calc(100vh - 138px);

    .image__slider {
        width: 100%;
        height: calc(100vh - 138px);
        object-fit: fill;
        object-position: center center;

        @media screen and (width<=900) {
            object-fit: cover;

        }
    }
}

.content__silder {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;
    @media screen and (width < 700px) {
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
        background-color: rgba($color:$mainColor, $alpha: 0.3);
    }
    .content {
        padding: 15px 50px;
        text-align: left;
        width: 50% !important;
        margin-bottom: 80px;
        
        @media screen and (width <=1280px) {
            width: 80% !important;
        }

        @media screen and (width <=780px) {
            width: fit-content !important;
        }

        @media screen and (max-width:464px) {
      
            padding: 15px;
        }

        span {

            color: $mainColor;
            font-family: IBM Plex Sans Arabic;

            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width:767px) {
                font-size: 35px !important;
            }

            @media screen and (max-width:464px) {
                font-size: 20px !important;
            }
        }

        p {
            font-size: 36px;
            line-height: normal;
            color: $dark !important;
            margin-bottom: 50px;
            text-align: right;
            /* Subheading */
            font-family: IBM Plex Sans Arabic;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 2.5rem;

            @media screen and (max-width:767px) {
                line-height: normal;
                margin-bottom: 30px;
                margin-top: 15px;
                font-size: 20px !important;
            }
        }

    }

    .contentAr {
        // background: linear-gradient(95deg, rgba(0, 0, 0, 0.00) 0%, rgba(#1A4DA6, 0.63) 100%);
    }

    .contentEn {
        // background: linear-gradient(-95deg, rgba(0, 0, 0, 0.00) 0%, rgba(#1A4DA6, 0.63) 100%);
    }
}

.content__silder-rtl {

    display: flex;
    justify-content: center;
    flex-direction: column;

    .content {
        margin-left: auto !important;
        display: flex;
        justify-content: start;
        flex-direction: column;
        text-align: right;
        padding: 15px 50px;

        @media screen and (width <=767px) {
            padding: 15px 10px;
        }

        span {
            text-align: right;

        }

        p {
            text-align: right;

        }

        .link__home {
            width: 100% !important;
            display: flex;
            justify-content: right;
            align-items: center;
        }

    }

}

.link__home_btn_sidebar {
    margin-bottom: 40px;

    .p-button {
        border-radius: 0px;
        background: $mainColor !important;
        width: 204px;
        height: 44px;

        span {
            color: var(--white, #FFF);
            text-align: center !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

@media (max-width: 768px) {
    .image__container {
        // margin-top: 80px;
        height: 250px !important;

        img {
            /* تعديلات للهواتف المحمولة */
            width: 100%;
            height: 250px !important;
        }

        .content__silder {
            .content {
                width: 100% !important;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            span {
                font-size: 30px !important;
                text-align: center !important;
            }

            p {
                color: var(--white, #FFF);
                text-align: center !important;
                font-family: IBM Plex Sans Arabic;
                font-size: 16px !important;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                width: 90%;

            }

        }
    }

    .link__home_btn_sidebar {
        margin-bottom: 15px;

        .p-button {
            border-radius: 20px;
            background: $mainColor;
            width: 150px;
            height: 34px;

            span {
                color: var(--white, #FFF);
                text-align: center;
                font-size: 14px !important;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@media (max-width: 500px) {
    .image__container {
        // margin-top: 80px;
        height: 250px !important;

        img {
            /* تعديلات للهواتف المحمولة */
            width: 100%;
            height: 250px !important;
        }

        .content__silder {
            .content {
                width: 100% !important;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            span {
                font-size: 26px !important;
                text-align: center !important;
            }

            p {
                color: var(--white, #FFF);
                text-align: center !important;
                font-family: IBM Plex Sans Arabic;
                font-size: 8px !important;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: center;
                width: 90%;

            }

        }
    }

    .link__home_btn_sidebar {
        margin-bottom: 15px;

        .p-button {
            border-radius: 20px;
            background: $mainColor;
            width: 150px;
            height: 34px;

            span {
                color: var(--white, #FFF);
                text-align: center;
                font-size: 14px !important;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1080px) {
    .image__container {
        // margin-top: 80px;
        height: 450px !important;

        img {
            /* تعديلات للهواتف المحمولة */
            width: 100%;
            height: 100% !important;
        }

        .content__silder {
            .content {
                padding: 15px;
                padding-right: 25px;
            }

            span {}

            p {
                color: var(--white, #FFF);
                font-family: cairo;
                font-size: 18px !important;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                text-align: center;
                margin-top: 10px;
            }

        }
    }

    .link__home_btn_sidebar {
        margin-bottom: 15px;

        .p-button {
            span {
                text-align: center !important;
            }
        }
    }
}

.app__slider {

    position: relative;
}

[dir="ltr"] .btn_sliders_left {
    position: absolute;
    bottom: 150px;
    left: 0;
    margin-bottom: -150px !important;
}

[dir="rtl"] .btn_sliders_left {
    position: absolute;
    bottom: 150px;
    right: 0;
    margin-bottom: -150px !important;
}

.btn_sliders_left {
    @media screen and (width<=1000px) {
        display: none !important;
    }

    .p-button {
        background-color: #F7FCFF !important;
        border-color: #FFF;
        border-radius: 0;
        width: calc(122px - 30px) !important;
        height: calc(103px - 20px);
        transition: all 0.5s;

        .p-button-icon {
            color: #221F1F;
            font-size: 20px;
        }

        &:hover {
            background-color: $mainColor !important;
            border-color: $mainColor;

            .p-button-icon {
                color: #FFF;
                font-size: 20px;
            }
        }
    }
}