@import '../../style/varibale';

.app__services_a {
    background-color: #F5F7FC;
    padding: 50px;

    img {
        border-radius: 11px 11px 0 0;
        height: 300px;
        object-fit: cover;
    }

    .content {
        background-color: #fff;
        padding: 30px 40px;
        border-radius: 0 0 11px 11px;

        @media screen and (width <=999px) {
            padding: 20px 30px;
        }

        h3 {
            font-size: 28px;

            @media screen and (width <=999px) {

                font-size: 20px;

            }
        }
    }
}

.app__clients_title {
    padding-top: 40px !important;
    .header {
        h3 {
            font-size: 40px;
            color: $dark;
            font-weight: 600;
            font-family: 'Roboto', sans-serif ;
            position: relative;
            width: fit-content;
            z-index: 1;
            color: $mainColor;

            &::after {
                content: '';
                position: absolute;
                left: -0px;
                bottom: 0%;
                background-color: #E7E6E7;
                border-radius: 11px;
                width: 60%;
                height: 24px;
                z-index: -1;
            }
        }
    }

    p {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #707070;
        margin-top: 20px;
        width: 80%;
        
        @media screen and (width <=999px) {
        line-height: 20px;

            font-size: 16px;
            width: 90%;
        
        }
    }
}