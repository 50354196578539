@import '../../style/varibale';

.fixed-top {
    top: 50px !important;
    margin: 0 !important;
    padding: 0 !important;
    background: #F7FCFF !important;
}

.main-nav {

    nav {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.navbar__social {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 88888;
    background-color: $mainColor !important;
    height: 50px !important;

    &.hide {
        display: none;
    }

    .navbar__social-Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: calc(100% - 100px);
        background-color: $mainColor !important;
        margin: auto;

        @media screen and (width < 767px) {
            width: 88% !important;
        }

        .calling {
            display: flex;
            gap: 60px;
            align-items: center;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 2.5px;
                height: 100%;
                background-color: white;
                left: 50%;
                border-radius: 5px;
            }

            svg {
                width: 15px !important;
                height: 15px !important;
            }

        }

        .center {
            display: flex;
            gap: 50px;
            align-items: center;
            position: relative;



            svg {
                width: 15px !important;

            }

            .nav1__home_svg {
                svg {
                    width: 15px !important;
                }
            }

            .change-lang {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                user-select: none;

                .text {
                    color: $colorLight;
                }

                .lang {
                    path {
                        fill: #fff !important;
                    }
                }
            }
        }

        .social {
            display: flex;
            gap: 25px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            .social_media {
                display: flex;
                gap: 25px;

                @media screen and (max-width:700px) {
                    display: none;
                }
            }

            position: relative;

            svg,
            .facebook {
                width: 16px !important;
                height: 16px !important;
            }


        }
    }

}

.navbar__social {
    /* Existing styles */
    transition: all 0.5s;

    &.hidden {
        display: none;
    }
}

.fixed-top {
    /* Existing styles */
    transition: all 0.5s;

    &.scroll {
        background: #F7FCFF !important;
        top: 0px !important;

        .Logo__RightClick {
            color: $mainColor !important;
        }

        .icons__menu {
            line {
                stroke: $mainColor;
            }
        }

        .app__menu-talk {
            background-color: $mainColor;
            color: $colorLight;

            &:hover {
                background-color: $colorDark;
                color: #FFFFFF;
            }
        }
    }
}

.main-nav {
    nav {
        height: 67px !important;
    }
}

.navbar-sm-icons-phone {
    path {
        fill: white;
    }
}

.app__navbar {
    background-color: #F7FCFF !important;

    .p-menuitem-text {
        a{
            color: $dark !important;
        }
    }

    .p-menubar {
        width: calc(100% - 100px) !important;
        margin: auto !important;
        padding: 0.5rem;
        background: #F7FCFF !important;
        color: #495057 !important;
        border: 1px solid transparent !important;
        border-radius: 0 !important;
        display: flex !important;

        @media screen and (width <=767px) {
            width: 100% !important;
        }
    }

}

[dir="ltr"] .p-menubar-root-list {
    display: flex;
    flex-direction: row-reverse;
}

[dir="rtl"] .app__navbar .p-menubar {
    display: flex;
    flex-direction: row-reverse;
}

[dir="ltr"] .p-menubar .p-menubar-custom {
    margin-right: auto !important;
    margin-left: 0 !important;
}

[dir="ltr"] .p-menubar .p-menubar-end {
    margin-right: auto !important;
    margin-left: 0 !important;
}


[dir="rtl"] .p-menubar .p-menubar-custom {
    margin-left: auto;
    margin-right: 0;
}

[dir="rtl"] .p-menubar .p-menubar-end {
    margin-left: auto;
    margin-right: 0;
}


[dir="rtl"] .change-lang {
    margin-bottom: 0px;
}

[dir="ltr"] .change-lang {
    margin-top: 0px;
}

.navbar_icons_sm{
    @media screen and (width < 767px) {
        display: none;
    }
}