@import '../../../style/varibale';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,700&display=swap');

.app__products {
    padding-bottom: 50px;

    .p-tabview-nav {
        justify-content: center;
    }

    .app__items {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            transition: all 0.5s;
            position: relative;
            z-index: 1;
            width: 343px !important;
            height: 311px !important;
            object-fit: contain;
            margin-bottom: 20px;
        }

        .app__items_bg {
            background-color: #DEE6F2;
            position: absolute;
            bottom: -50px;
            width: 100%;
            height: 150px;
            z-index: 0;
            border-radius: 0px 0 6px 6px;
            overflow: hidden;

            .app__items_bg_footer {
                width: 100%;
                padding: 30px;
                height: 100%;
                display: flex;
                align-items: end;
                justify-content: space-between;

                .item_name {
                    font-size: 20px;
                    font-family: 'Roboto', sans-serif  ;
                    font-weight: 500;
                    color: $dark;
                }

                .more_details {
                    transition: all 0.5s;
                    position: absolute;


                    span {
                        color: $dark;
                    }

                    i {
                        color: $dark;
                        font-size: 15px;
                    }
                }
            }

            transition: all 0.5s;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }

            .app__items_bg {
                background-color: #B5C8E8;
            }

        }
    }

}

[dir="ltr"] .app__products .app__items_bg .app__items_bg_footer .more_details {
    right: -100px;
}

[dir="ltr"] .app__items:hover .app__items_bg_footer .more_details {
    position: absolute;
    right: 20px;
}

[dir="rtl"] .app__products .app__items_bg .app__items_bg_footer .more_details {
    left: -100px;
}
    
[dir="rtl"] .app__items:hover .app__items_bg_footer .more_details {
    position: absolute;
    left: 20px;
}

.app__products_title {
    .header {
        h3 {
            font-size: 40px;
            color: $dark;
            font-weight: 600;
            font-family: 'Roboto', sans-serif ;
            position: relative;
            width: fit-content;
            z-index: 1;

            &::after {
                content: '';
                position: absolute;
                right: -10px;
                bottom: 10%;
                background-color: #E7E6E7;
                border-radius: 11px;
                width: 54%;
                height: 20px;
                z-index: -1;
            }
        }
    }

    p {
        font-size: 30px;

    }
}

.p-tabview .p-tabview-nav {
    background: #ffffff;
    border: none !important;
    border-width: 0 0 2px 0;
}

[dir="rtl"] .image__container .image__slider {
    transform: scaleX(-1);
}

.app__offers {

    width: 85%;
    margin: auto;

    @media screen and (width < 1025px) {
        .row_md {
            display: flex;
            flex-direction: column;

            .col_md {
                width: 100%;
            }
        }
    }

    .app__offer_items {
        padding: 5px 30px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        align-items: center;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-size: 100% 200%;
        overflow: hidden;

        @media screen and (width < 525px) {
            padding: 20px 30px;
        }

        .content {
            display: flex;
            gap: 10px;
            flex-direction: column;

            span {
                font-size: 20px;
                font-weight: 400;

                @media screen and (width < 1286px) {
                    font-size: 16px;
                }

                @media screen and (width < 525px) {
                    font-size: 16px;
                }

                @media screen and (width < 429px) {
                    font-size: 14px;
                }
            }

            h3 {
                line-height: 35px;
                font-weight: 800;
                font-family: 'Roboto', sans-serif ;

                @media screen and (width < 1286px) {
                    font-size: 20px;
                }

                @media screen and (width < 525px) {
                    font-size: 20px;
                }

                @media screen and (width < 429px) {
                    font-size: 16px;
                    line-height: 28px;
                }
            }

            .p-button {
                border-radius: 0;
                width: fit-content;
                background-color: $mainColor;
                height: 44px !important;

                @media screen and (width < 1286px) {
                    padding: 5px;
                    height: 33px !important;
                }

                @media screen and (width < 525px) {
                    padding: 5px;
                    height: 33px !important;

                }

                @media screen and (width < 429px) {
                    height: 28px !important;
                }

                span {
                    font-size: 19px;
                    font-weight: 600;
                    @media screen and (width < 1286px) {
                        font-size: 14px;
                    }
    
                    @media screen and (width < 525px) {
                        font-size: 14px;
                    }

                    @media screen and (width < 429px) {
                        font-size: 12px;
                    }
                }
            }
        }

        .img {
            img {
                width: 343px !important;
                height: 311px !important;
                transition: all 0.5s;
                @media screen and (width < 1286px) {
                    width: 250px !important;
                    height: 250px !important;
                }
                @media screen and (width < 767px) {
                    width: 250px !important;
                    height: 250px !important;
                }

                @media screen and (width < 525px) {
                    width: 200px !important;
                    height: 200px !important;
                }

                @media screen and (width < 420px) {
                    width: 150px !important;
                    height: 150px !important;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }
}

.app__products{
    .Mui-selected {
        color: $mainColor !important;
         
    }
}

.css-tkn350-MuiTabs-root{
    direction: ltr !important;
}
 