@import '../../style/varibale';

.app__products_page {
    padding-top: 10px !important;
    background: #F7FCFF !important;

    .Mui-selected {
        color: $mainColor !important;
    }
}

// *************** Product Details
.app__Product_details {
    background-color: #F5F7FC !important;


    .card__image {
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: calc(100vh - 130px);

        .product-detail-container {
            display: flex;
            gap: 100px;
            margin: 40px;
            margin-top: 60px;
            color: #324d67;
        }

        .product-detail-image {
            padding: 20px;
            cursor: pointer;
            border-radius: 5px;
            // background-color: #ebebeb;
            transition: 0.3s ease-in-out;

            img {
                width: 450px;
                height: 450px;
            }
        }


        .product-detail-image:hover {
            // background-color: $footerColor; 
        }

        .small-images-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;

        }

        .small-image {
            border-radius: 0px;
            background-color: white;
            width: 90px;
            height: 90px;
            padding: 5px;
            cursor: pointer;
        }

        .Prudact_name {
            font-size: 16px !important;
            font-family: cairo !important;
            font-weight: 700;
            letter-spacing: 1px;
            color: rgba($color: $dark , $alpha: 0.9);
        }

        .small-btn-container {
            margin-top: auto;
            margin-bottom: 50px;

            .p-button {
                border-radius: 0;
                background-color: $mainColor;

                span {
                    font-size: 18px;
                    padding: 0 20px;
                    font-weight: 100;
                    font-family: cairo !important;
                }
            }
        }

        .selected-image {
            border: 1px solid $colorGrey-1;
        }

        .reviews {
            color: #f02d34;
            margin-top: 10px;
            display: flex;
            gap: 5px;
            align-items: center;
        }

        .overlay_small-image {
            h1 {

                font-size: 25px !important;
            }
        }


    }

    .card__content {
        background-color: white !important;

        .header {
            h2 {
                line-height: 80px;
                color: $dark;
                font-family: $fontRoboto !important;
            }

            span {
                font-size: 20px;
                color: $mainColor;
                text-transform: capitalize;
            }
        }

        .body {
            margin-top: 30px;

            h3 {
                color: $mainColor;
                font-size: 22px;
            }

            p {
                font-size: 18px;
                line-height: 30px;
                color: #000 !important;
                opacity: 80%;
                margin-bottom: 50px;
                font-weight: 300;
                margin-top: 15px;
                text-align: justify;

            }
        }

        .size {
            .size_header {
                font-size: 24px;
                color: $mainColor;
                font-family: $fontRoboto;
                font-weight: 800;
                letter-spacing: 1.5px;
            }

            .content {
                gap: 250px;
                margin-top: 30px !important;

                @media screen and (width < 959px) {
                    gap: 100px;
                }

                @media screen and (width < 959px) {
                    flex-direction: column;
                }

                .width_num,
                .hight_num {
                    text-align: center;
                    color: $mainColor;
                    font-size: 25px;
                    font-weight: 500;
                    font-family: $fontRoboto;
                }

                .width_text,
                .hight_text {
                    text-align: center;
                    color: $dark;
                    font-size: 16px;
                    font-weight: 600;
                    font-family: $fontRoboto;
                }

                .height {
                    position: relative;
                    padding-left: 30px;

                    @media screen and (width < 959px) {
                        margin: auto;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: #000;
                        height: 100%;
                        width: 1px;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0%;
                        background-color: #000;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                    }
                }


                .width {
                    @media screen and (width < 959px) {
                        margin: auto    ;
                    }
                }

                .width_after {
                    height: 1px;
                    width: 100px;
                    background-color: $dark;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: -400%;
                        background-color: #000;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: -400%;
                        background-color: #000;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .color {
            margin-top: 70px !important;
            margin-bottom: 100px !important;

            .size_header {
                font-size: 24px;
                color: $mainColor;
                font-family: $fontRoboto;
                font-weight: 800;
                letter-spacing: 1.5px;
            }

            display: flex;
            flex-direction: column;

            // gap: 1px;
            .flex_color {
                display: flex;
                flex-direction: row;
                gap: 35px;

                .s-color,
                .f-color {
                    width: 120px;
                    height: 35px;
                }
            }
        }
    }
}


.card__image_lg {
    @media screen and (width < 959px) {
        display: none !important;
    }
}

.card__image_sm {
    display: none;

    @media screen and (width < 959px) {

        display: flex !important;
    }

    .card__image_sm {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;

        .product-detail-container {
            display: flex;
            color: #324d67;
        }

        .product-detail-image {
            padding: 20px;
            cursor: pointer;
            border-radius: 5px;
            // background-color: #ebebeb;
            transition: 0.3s ease-in-out;

            img {
                width: 450px;

                @media screen and (width < 959px) {
                    width: 300px;

                }
            }
        }


        .product-detail-image:hover {
            // background-color: $footerColor; 
        }

        .small-images-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;

        }

        .small-image {
            border-radius: 0px;
            background-color: white;
            width: 90px;
            height: 90px;
            padding: 5px;
            cursor: pointer;
        }

        .Prudact_name {
            font-size: 16px !important;
            font-family: cairo !important;
            font-weight: 700;
            letter-spacing: 1px;
            color: rgba($color: $dark , $alpha: 0.9);
        }

        .small-btn-container {
            margin-top: 20px;

            .p-button {
                border-radius: 0;
                background-color: $mainColor;

                span {
                    font-size: 16px;
                    padding: 0 15px;
                    font-weight: 100;
                    font-family: cairo !important;
                }
            }
        }

        .selected-image {
            border: 1px solid $colorGrey-1;
        }

        .reviews {
            color: #f02d34;
            margin-top: 10px;
            display: flex;
            gap: 5px;
            align-items: center;
        }

        .overlay_small-image {
            h1 {

                font-size: 25px !important;
            }
        }


    }

    .card__content {
        background-color: white !important;

        .header {
            h2 {
                line-height: 80px;
                color: $dark;
                font-family: $fontRoboto !important;
            }

            span {
                font-size: 20px;
                color: $mainColor;
                text-transform: capitalize;
            }
        }

        .body {
            margin-top: 30px;

            h3 {
                color: $mainColor;
                font-size: 22px;
            }

            p {
                font-size: 18px;
                line-height: 30px;
                color: #000 !important;
                opacity: 80%;
                margin-bottom: 50px;
                font-weight: 300;
                margin-top: 15px;
                text-align: justify;

            }
        }

        .size {
            .size_header {
                font-size: 24px;
                color: $mainColor;
                font-family: $fontRoboto;
                font-weight: 800;
                letter-spacing: 1.5px;
            }

            .content {
                gap: 250px;
                margin-top: 30px !important;
                flex-direction: row !important;

                @media screen and (width < 959px) {

                    flex-direction: column !important;
                }

                .width_num,
                .hight_num {
                    text-align: center;
                    color: $mainColor;
                    font-size: 25px;
                    font-weight: 500;
                    font-family: $fontRoboto;
                }

                .width_text,
                .hight_text {
                    text-align: center;
                    color: $dark;
                    font-size: 16px;
                    font-weight: 600;
                    font-family: $fontRoboto;
                }

                .height {
                    position: relative;
                    padding-left: 30px;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: #000;
                        height: 100%;
                        width: 1px;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0%;
                        background-color: #000;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                    }
                }

                .width {}

                .width_after {
                    height: 1px;
                    width: 100px;
                    background-color: $dark;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: -400%;
                        background-color: #000;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: -400%;
                        background-color: #000;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .color {
            margin-top: 70px !important;
            margin-bottom: 100px !important;

            .size_header {
                font-size: 24px;
                color: $mainColor;
                font-family: $fontRoboto;
                font-weight: 800;
                letter-spacing: 1.5px;
            }

            display: flex;
            flex-direction: column;

            // gap: 1px;
            .flex_color {
                display: flex;
                flex-direction: row;
                gap: 35px;

                .s-color,
                .f-color {
                    width: 120px;
                    height: 35px;
                }
            }
        }
    }
}