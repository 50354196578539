@import '../../../style/varibale';

.app__Subscribe {
    overflow: hidden;
    position: relative;

    .mail_subscribe {
        width: 60%;
        margin: auto;
        position: absolute;
        bottom: 10%;
        left: 20%;
        transform: translateX(-50%);
        transform: translateY(-50%);
        z-index: 99 !important;
        background-color: white;
        border-radius: 45px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        padding: 5px 5px !important;
        height: 65px;
 
        @media screen and (width < 1286px) {
            left: 10%;
            width: 80%;
        }

        @media screen and (width < 767px) {
            height: 55px;
            left: 5%;
            width: 90%;
        }

        .Touch {
            color: $light;
            background-color: $mainColor;
            padding: 5px;
            border-radius: 45px;
            width: 160px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            user-select: none;

            @media screen and (width < 767px) {
                font-size: 14px;
            }
        }

        .p-inputtext {
            // border-radius: 0 45px 45px 0;
            border: none;
            width: 100%;
            @media screen and (width < 767px) {
                font-size: 14px;
            }
        }

        .p-button {
            transition: all 0.6s !important;
            padding: 0px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            gap: 6px;
            border-radius: 45px !important;
            width: fit-content !important;
            margin: 0 !important;

            span {

                color: $mainColor;
                @media screen and (width < 767px) {
                    font-size: 14px;
                }
            }

            svg {
                width: 34px;
                height: 35px;

                display: none;

                path {
                    stroke: $mainColor;

                }
            }

            &:hover {
                transition: all 0.6s;

                svg {

                    display: flex;
                }
            }
        }
    }

    .col {
        padding: 0 3px !important;
    }

    .app__app__Subscribe_sm {
        @media screen and (width < 767px) {
            display: none;
        }
    }

    .overlay1,
    .overlay2,
    .overlay3,
    .overlay4 {
        position: relative;

        .overlay {
            transition: all 0.5s;
            position: absolute;
            inset: 0;
            background-color: rgba($color: #221F1F, $alpha: 75%);
        }
    }

    .overlay1:hover {
        .overlay {
            background-color: rgba($color: $mainColor, $alpha: 50%);
        }
    }

    .overlay2:hover {
        .overlay {
            background-color: rgba($color: $mainColor, $alpha: 50%);
        }
    }

    .overlay3:hover {
        .overlay {
            background-color: rgba($color: $mainColor, $alpha: 50%);
        }
    }

    .overlay4:hover {
        .overlay {
            background-color: rgba($color: $mainColor, $alpha: 50%);
        }
    }
}