@import '../../style/varibale';

.carousel-indicators {
    right: none !important;
}

.app__slider-home {
    position: relative;
    overflow: hidden;

    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
        width: 15px !important;
    }

    .carousel-indicators {

        bottom: 20px !important;


        button {
            width: 15px !important;
            transition: all 0.9s !important;
        }

        .active {
            width: 30px !important;
            background-color: $light !important;
        }
    }

    .slider__home {
        height: calc(100vh - 138px);
        object-fit: cover;
        object-position: center;
        z-index: -5 !important;
    }

    .content__silder {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100% !important;
        background-color: rgba($color: #221F1F, $alpha: 45%) !important;

        .content {
            width: 70% !important;
            padding: 15px 50px;
            border-radius: 0px 10px 10px 0px;
            // backdrop-filter: blur(6px);
            // -webkit-backdrop-filter: blur(6px);
            // background-color: rgba($color: #000000, $alpha: 0.4) !important;
            // border-left: 5px solid #FA6409;
            margin-top: 25%;
            margin-left: 5%;

            @media screen and (max-width:1029px) {
                width: 80% !important;
            }

            @media screen and (max-width:767px) {
                width: 95% !important;
                padding: 15px;
            }

            @media screen and (max-width:464px) {
                width: 95% !important;
                padding: 15px;

            }

            span {

                font-style: normal;
                font-weight: 400;
                font-size: 43px;
                text-transform: capitalize;
                color: #FFFFFF;
                font-family: $fontRoboto !important;

                @media screen and (max-width:767px) {
                    font-size: 34px !important;

                }

                @media screen and (max-width:464px) {
                    font-size: 30px !important;

                }
            }

            p {
                font-size: 23px;
                line-height: 35px;
                color: #FFFFFF !important;
                opacity: 80%;
                margin-bottom: 50px;
                font-weight: 300;
                margin-top: 15px;

                @media screen and (max-width:767px) {
                    // font-weight: 600;
                    line-height: normal;
                    margin-bottom: 30px;
                    margin-top: 15px;

                    font-size: 20px !important;
                }

                @media screen and (max-width:400px) {
                    // font-weight: 600;
                    line-height: normal;
                    margin-bottom: 30px;
                    margin-top: 15px;
                    font-size: 16px !important;
                    opacity: 100%;
                }
            }

        }
    }
}

[dir="ltr"] .app__slider-home .carousel-indicators {

    left: -78% !important;
    z-index: 9999999999 !important;

}

@media screen and (max-width:1040px) {
    [dir="ltr"] .app__slider-home .carousel-indicators {
        left: -70% !important;
    }
}

@media screen and (max-width:767px) {
    [dir="ltr"] .app__slider-home .carousel-indicators {
        left: 0% !important;
    }
}

[dir="ltr"] .app__slider-home .content__silder .content {
    text-align: left;
}



[dir="ltr"] .app__slider-home .content__silder .content p {
    text-align: left;
    width: 100% !important;
}



@media screen and (max-width:767px) {
    [dir="ltr"] .app__slider-home .carousel-indicators {
        left: -70% !important;

    }

    [dir="ltr"] .app__slider-home .content__silder .content p {
        text-align: left;
    }

    [dir="ltr"] .app__slider-home .content__silder .content span {
        text-align: left;
    }

    [dir="ltr"] .app__slider-home .content__silder .content {
        text-align: left;
    }
}

@media screen and (max-width:464px) {

    [dir="ltr"] .app__slider-home .content__silder .content p {
        text-align: center;
    }

    [dir="ltr"] .app__slider-home .content__silder .content span {
        text-align: center;
    }

    [dir="ltr"] .app__slider-home .content__silder .content {
        text-align: center;

    }
}

[dir="rtl"] .app__slider-home .carousel-indicators {

    left: 66% !important;
    z-index: 9999999999 !important;

}

@media screen and (max-width:1040px) {
    [dir="rtl"] .app__slider-home .carousel-indicators {
        left: 70% !important;
    }
}

@media screen and (max-width:767px) {
    [dir="rtl"] .app__slider-home .carousel-indicators {
        left: 0% !important;
    }
}

[dir="rtl"] .app__slider-home .content__silder .content {
    text-align: right;
}



[dir="rtl"] .app__slider-home .content__silder .content p {
    text-align: right;

}



@media screen and (max-width:767px) {
    [dir="rtl"] .app__slider-home .carousel-indicators {
        left: -70% !important;

    }

    [dir="rtl"] .app__slider-home .content__silder .content p {
        text-align: right;
    }

    [dir="rtl"] .app__slider-home .content__silder .content span {
        text-align: right;
    }

    [dir="rtl"] .app__slider-home .content__silder .content {
        text-align: right;
    }
}

@media screen and (max-width:464px) {

    [dir="rtl"] .app__slider-home .content__silder .content p {
        text-align: center;
    }

    [dir="rtl"] .app__slider-home .content__silder .content span {
        text-align: center;
    }

    [dir="rtl"] .app__slider-home .content__silder .content {
        text-align: center;

    }
}



// *********************** about us
.app__about_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 80px;

    img {
        border: 1px solid #221F1F;
        border-radius: 15px;
        display: flex;
        margin: auto;

        @media screen and (width <=767px) {

            width: 50% !important;
        }

        @media screen and (width <=450px) {
            font-size: 20px;
        }
    }

    .content {
        h1 {
            font-family: $fontRoboto !important;
            font-weight: 600;

            @media screen and (width <=767px) {
                font-size: 25px;
            }

            @media screen and (width <=450px) {
                font-size: 20px;
            }
        }

        ul {
            margin: auto;
            width: fit-content;

            li {
                font-size: 22px;
                font-family: $fontRoboto;
                color: $mainColor;

                @media screen and (width <=767px) {
                    font-size: 19px;
                }

                @media screen and (width <=450px) {
                    font-size: 16px;
                }
            }
        }
    }
}

.app__about_title2 {
    .header {
        h3 {
            font-size: 40px;
            color: $light;
            font-weight: 600;
            font-family: 'Roboto', sans-serif ;
            position: relative;
            width: fit-content;
            z-index: 1;
            color: #221F1F;

            @media screen and (width < 767px) {
                font-size: 30px;
            }

            &::after {
                content: '';
                position: absolute;
                left: -10px;
                bottom: 10%;
                background-color: rgba($color: #221F1F, $alpha: 8%);
                border-radius: 11px;
                width: 70%;
                height: 20px;
                z-index: -1;

                @media screen and (width < 767px) {
                    bottom: 0%;
                }
            }
        }
    }

    p {
        font-size: 20px;
        line-height: 32px;
        font-family: $fontRoboto;
        margin-top: 10px;
        color: $dark;
        font-weight: 300;
        text-align: justify;

        @media screen and (width < 680px) {
            font-size: 16px;
            line-height: 27px;
            text-align: inherit;

        }
    }
}

.app__about_title2 {
    width: 95% !important;
}

.app__vision {
    h4 {
        font-size: 30px;

        @media screen and (width<767px) {
            font-size: 20px;
        }
    }

    ul {
        margin: 0px;
        padding-left: 16px;

        // list-style: d;
        li {
            font-size: 20px;
            line-height: 32px;
            font-family: $fontRoboto;
            margin-top: 10px;
            color: $dark;
            font-weight: 300;
            text-align: justify;

            @media screen and (width < 680px) {
                font-size: 16px;
                line-height: 27px;
                text-align: inherit;

            }
        }
    }
}

.app__value {
    .app__about_title2 {
        .content {
            .Credibility {
                .header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    border-radius: 30px;
                    width: 190px;
                    padding: 2px 20px !important;
                    background-color: $mainColor;

                    @media screen and (width < 680px) {
                        width: fit-content;
                        padding: 2px 15px !important;

                    }

                    img {
                        width: 25px;
                    }

                    span {
                        font-size: 20px;
                        font-weight: 600;
                        color: $light;

                        @media screen and (width < 680px) {
                            font-size: 16px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }
}