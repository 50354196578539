@import '../../../style/varibale';

.app__about_home .app__aboutus {
    position: relative;

    @media screen and (width < 680px) {
        img {
            height: 480px;
            object-fit: cover;
        }
    }


}

.app__about_home .app__aboutus .content {
    position: absolute;
    background-color: $mainColor;

    @media screen and (width < 767px) {
        opacity: .8;
    }

    padding: 29px;
    width: 600px;
    top: 50%;
    transform: translateX(40%);
    transform: translateY(-50%);
    z-index: 999 !important;

}

[dir="ltr"] .app__about_home .app__aboutus .content {

    left: -20%;

    @media screen and (width < 767px) {
        padding: 20px 15px;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        width: 86%;
    }

}

[dir="rtl"] .app__about_home .app__aboutus .content {

    right: -20%;

    @media screen and (width < 767px) {
        padding: 20px 15px;
        right: -36%;
        transform: translate(-50%, -50%) !important;
        width: 86% !important;
    }

}

.app__about_home .app__aboutus .content .p-button {
    margin-left: 10px;
    margin-top: 20px;
    border-radius: 0 !important;
    background-color: #fff !important;
    padding: 10px 50px;

    @media screen and (width < 767px) {
        padding: 5px 40px;
        margin-top: 10px;
    }
}

.app__about_home .app__aboutus .content .p-button span {
    font-size: 16px;
    color: $mainColor;

    @media screen and (width < 767px) {
        font-size: 14px;

    }
}

.app__about_title {
    .header {
        h3 {
            font-size: 40px;
            color: $light;
            font-weight: 600;
            font-family: 'Roboto', sans-serif;
            position: relative;
            width: fit-content;
            z-index: 1;

            @media screen and (width < 767px) {
                font-size: 30px;
            }

            &::after {
                content: '';
                position: absolute;
                left: -10px;
                bottom: 10%;
                background-color: rgba($color: #fff, $alpha: 19%);
                border-radius: 11px;
                width: 70%;
                height: 20px;
                z-index: -1;

                @media screen and (width < 767px) {
                    bottom: 0%;
                }
            }
        }
    }

    p {
        font-size: 20px;
        line-height: 32px;
        font-family: $fontRoboto;
        margin-top: 10px;
        color: $light;
        font-weight: 300;

        @media screen and (width < 680px) {
            font-size: 16px;
            line-height: 27px;

        }
    }
}

// ****************************** Quality *************************
.app_quality {
    overflow: hidden;
    padding-top: 12px;

    .content {
        height: 100%;
        padding-left: 50px;
        padding-bottom: 50px;
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
        gap: 15px;

        @media screen and (width < 767px) {
            padding-left: 10px;

        }

        h2 {
            color: $mainColor;
            font-size: 40px;
            font-weight: 600;

            @media screen and (width < 767px) {
                font-size: 30px;

            }
        }

        ul {
            margin-top: 10px;
            list-style: none;
            // margin: 0;

            display: flex;
            gap: 30px;
            flex-direction: column;

            @media screen and (width < 767px) {
                gap: 15px;
            }

            li {
                font-size: 20px;
                color: $dark;
                font-weight: 500;
                font-family: $fontRoboto;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    left: -15px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #fff;
                    border: 1px solid $mainColor;
                    width: 5px;
                    height: 15px;
                }
                @media screen and (width < 767px) {
                    font-size: 16px;

                }
            }
        }
    }
}

[dir="rtl"] .app_quality img {
    transform: rotateY(178deg);
}

[dir="rtl"] .app_quality .content {
    padding-right: 10px;
}

[dir="rtl"] .app_quality .content ul li::before { 
        right: -15px;  
}