@import '../../style/varibale';
.app__clients_title {
    .header {
        h3 {
            font-size: 40px;
            color: $dark;
            font-weight: 600;
            font-family: 'Roboto', sans-serif ;
            position: relative;
            width: fit-content;
            z-index: 1;
            color: $mainColor;
            &::after {
                content: '';
                position: absolute;
                left: -0px;
                bottom: 0%;
                background-color: #E7E6E7;
                border-radius: 11px;
                width: 54%;
                height: 24px;
                z-index: -1;
            }
        }
    }

    p {
        font-size: 30px;

    }
}